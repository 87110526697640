import React,{Component} from 'react';
import VariantItem from './variantItem';

class VariantSkeleton extends Component{

    
    render(){
        return(
            <div className="variant-skeleton-container">
                
                <div className="variant-item-container-skeleton">
                    <VariantItem/>
                    <VariantItem/>
                    <VariantItem/>
                    <VariantItem/>
                </div>
            </div>
        );
    }
}

export default VariantSkeleton;