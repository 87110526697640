import React,{Component} from 'react';
// import "static/css/global/skeleton.css";

class HomePageBannerSkeleton extends Component{
    render(){
        return (
            <div className="home-page-banner-skeleton">
                <div className="skeleton-banner skeleton">
                    
                </div>
            </div>
        );
    }
}

export default HomePageBannerSkeleton;