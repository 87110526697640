import React, { Component } from 'react';
import styles from './skeletons.module.scss';

class CartSkeleton extends Component {
    render() {
        return (
            <div className={styles['cart-skeleton'] + " HK-pagecontainer"}>
                <div className={styles['cart-image-cont']}>
                    <div className={styles['cart-image-left']}>
                        <div className={styles['progress-bar']} >
                            <div className={styles['horizontal-rule']}>
                                <div className={styles['start']}/>
                                <div className={styles['start']}/>
                            </div>
                            <div className={styles['steps']}>
                                <div className={styles['step']}/>
                                <div className={styles['step']}/>
                                <div className={styles['step']}/>
                            </div>
                        </div>
                        <div className={styles['cart-cart']}>
                        <div className={styles['cart-cart-left']}>
                            <div className={styles['cart-content']}>
                                <div className={styles['image']} />
                                    <div className={styles['desc']}>
                                        <div className={styles['line-1']} />
                                        <div className={styles['line-2']} />
                                        <div className={styles['line-3']} />
                                        <div className={styles['line-4']} />
                                        <div className={styles['line-5']} />
                                        <div className={styles['buttons']}>
                                            <div className={styles['quantity']}/>
                                            <div className={styles['wishlist-button']}/>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['image2']} />
                    <div className={styles['combo']} />
                    <div className={styles['cart-small-images']}>
                            <div className={styles['cart-small-img']} />
                            <div className={styles['cart-small-img']} />
                            <div className={styles['cart-small-img']} />
                            <div className={styles['cart-small-img']} />
                            <div className={styles['cart-small-img']} />
                        </div>
                        <div className={styles['small-images']}>
                            <div className={styles['small-img']} />
                            <div className={styles['small-img']} />
                            <div className={styles['small-img']} />
                            <div className={styles['small-img']} />
                        </div>
                    </div>
                    <div className={styles['cart-image-right']}>
                        <div className={styles['cart-order-summary']} />
                        <div className={styles['cart-flv-price-cont']}>
                            <div className={styles['cart-flv-price-item']} />
                            <div className={styles['cart-flv-price-item-1']} />
                        </div>
                        <div className={styles['delivery-fees-cont']}>
                            <div className={styles['delivery-fees-cont-1']} />
                            <div className={styles['delivery-fees-cont-2']} />
                        </div>
                        <div className={styles['discount-cont']}>
                            <div className={styles['discount-cont-1']} />
                            <div className={styles['discount-cont-2']} />
                        </div>
                        <div className={styles['total-amount-cont']}>
                            <div className={styles['total-amount-cont-1']} />
                            <div className={styles['total-amount-cont-2']} />
                        </div>
                        <div className={styles['mbcash']} />
                        <div className={styles['checkout']} />
                        <div className={styles['apply-offer']} />
                        <div className={styles['apply-offer-placeholder']} />
                        <div className={styles['view-all']} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CartSkeleton;