import React,{Component} from 'react';
import CategoryImageSkeleton from './CategoryImageSkeleton';

class CategorySkeleton extends Component{

    render(){
        return(
            <div className="cart-skeleton-container">
                <div className="variant-item-container-skeleton">
                    <CategoryImageSkeleton/>
                    <CategoryImageSkeleton/>
                    <CategoryImageSkeleton/>
                    <CategoryImageSkeleton/>
                    <CategoryImageSkeleton/>
                </div>
                <div className="skeleton-footer-title skeleton"></div>
            </div>
        );
    }
}

export default CategorySkeleton;