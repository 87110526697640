import React,{Component} from 'react';
// import "static/css/global/skeleton.css";

class Skeleton3 extends Component{
    render(){
        return (
            <div className="skeleton3">
                <div className="skeleton-bar skeleton"></div>
                <div className="skeleton-short-bar skeleton"></div>
                <div className="skeleton-bar skeleton"></div>
                <div className="skeleton-short-bar skeleton"></div>
            </div>
        );
    }
}

export default Skeleton3;