export const getPaymentModeAction = (data)=>{
    return {
        type:"GET_PAYMENT_MODE",
        payload:data
    }
}

export const codAllowedAction = (data)=>{
    return {
        type:"IS_COD_ALLOWED",
        payload:data
    }
}

export const paymentPrepareAction = (data)=>{
    return {
        type:"CART_PAYMENT_ACTION",
        payload:data
    }
}

export const codDataAction = (data)=>{
    return {
        type:"COD_DATA",
        payload:data
    }
}

export const cardOfferAvldAction = (data)=>{
    return {
        type:"CARD_OFFER_AVAILED",
        payload:data
    }
}

export const offerIssuerAction = (data) =>{
    return{
        type: "OFFER_ISSUER_MAPPING",
        payload: data
    }
}

export const issuerIdentifireAction = (data)=>{
    return {
        type:"ISSUER_IDENTIFIRE",
        payload:data
    }
}

export const SavedCardBtnAction = (data)=>{
    return {
        type:"SAVED_CARD",
        payload:data
    }
}

export const checkoutStepsAction = (data)=>{
    return {
        type:"CHECKOUT_STEPS",
        payload:data
    }
}
export const PaymentPropsValues = (data)=>{
    return {
        type:"PAYMENT_PROPS_VALUE",
        payload:data
    }
}