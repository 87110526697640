import React,{Component} from 'react';

class VariantItem extends Component{
    render(){
        return(
            <div className="variant-item-skeleton">
                <div className="variant-item-img-skeleton">
                    <div className="skeleton-bar skeleton"></div>
                </div>
                <div className="variant-item-content-skeleton">
                    <div className="skeleton-variant-short-bar skeleton"></div>
                    <div className="skeleton-variant-short-bar skeleton"></div>
                    <div className="skeleton-variant-button skeleton"></div>
                    <div className="skeleton-variant-button skeleton"></div>
                </div>
                <div className="variant-item-button-skeleton">
                    <div className="skeleton-variant-button skeleton"></div>
                    <div className="skeleton-variant-button skeleton"></div>
                </div>
                
            </div>
        );
    }
}

export default VariantItem;