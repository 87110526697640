import React,{Component} from 'react';

class CategoryImageSkeleton extends Component{
    render(){
        return(
            <div className="popular-item-skeleton">
                <div className="popular-item-img-skeleton">
                    <div className="skeleton-bar skeleton"></div>
                </div>
            </div>
        );
    }
}

export default CategoryImageSkeleton;