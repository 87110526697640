import React,{Component} from 'react';
// import "static/css/global/skeleton.css";

class Skeleton1 extends Component{
    render(){
        return (
            <div className="skeleton1">
                <div className="skeleton1-inner">
                    <div className="skeleton1-left">
                        <div className="skeleton-img skeleton"></div>
                    </div>
                    <div className="skeleton1-right">
                        <div className="skeleton-bar skeleton"></div>
                        <div className="skeleton-bar skeleton"></div>
                        <div className="skeleton-bar skeleton"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Skeleton1;