import React,{Component} from 'react';
import VariantItem from './variantItem';

class MobListSkeleton extends Component{
    render(){
        return(
            <div className="mob-list-skeleton">
                <VariantItem/>
                <VariantItem/>
                <VariantItem/>
                <VariantItem/>
            </div>
        );
    }
}

export default  MobListSkeleton;