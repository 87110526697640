import React,{Component} from 'react';
import CartItem from './CartItem';

class VariantSkeleton extends Component{

    
    render(){
        return(
            <div className="cart-skeleton-container">
                {/* <div className="skeleton-title skeleton"></div> */}
                <div className="variant-item-container-skeleton">
                    <CartItem/>
                    <CartItem/>
                    <CartItem/>
                    <CartItem/>
                </div>
                <div className="skeleton-footer-title skeleton"></div>
            </div>
        );
    }
}

export default VariantSkeleton;