import React,{Component} from 'react';

class CartItem extends Component{
    render(){
        return(
            <div className="cart-item-skeleton">
                <div className="cart-item-img-skeleton">
                    <div className="skeleton-bar skeleton"></div>
                </div>
                <div className="cart-item-content-skeleton">
                    <div className="skeleton-short-bar skeleton"></div>
                    <div className="skeleton-short-bar skeleton"></div>
                    <div className="skeleton-short-bar skeleton"></div>
                    <div className="skeleton-button skeleton"></div>
                </div>
            </div>
        );
    }
}

export default CartItem;