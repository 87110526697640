import React, { Component } from 'react';
import Skeleton2 from 'utility/templates/skeletons/skeleton2';
import Skeleton3 from 'utility/templates/skeletons/skeleton3';
import Skeleton1 from 'utility/templates/skeletons/skeleton1';
import Skeleton4 from 'utility/templates/skeletons/skeleton4';
import DesktopSliderSkeleton from 'utility/templates/skeletons/desktopSliderSkeleton';
import VariantSkeleton from './variantSkeleton';
import CatSkeleton from './CategoryPageSkeleton';
import MobListSkeleton from './MobListSkeleton';
import PDPSkeleton from './PDPSkeleton';
import MobPDPSkeleton from './MobPDPSkeleton';
import HomePageBannerSkeleton from './HomePageBannerSkeleton';
import HomePageVariantSkeleton from './HomePageVariantSkeleton';
import CartSkeleton from './CartSkeleton';
import CategorySkeleton from './CategorySkeleton';

class SkeletonPattern extends Component {
    componentDidMount() {
        
    }
    skeletonFactoryPattern(val, key) {
        let skeletonRender;
        switch (val) {
            case 1:
                skeletonRender = <Skeleton1 key={key} />;
                break;
            case 2:
                skeletonRender = <Skeleton2 key={key} />;
                break;
            case 3:
                skeletonRender = <Skeleton3 key={key} />;
                break;
            case 4:
                skeletonRender = <Skeleton4 key={key} />;
                break;
            case 5:
                skeletonRender = <DesktopSliderSkeleton key={key} />;
                break;
            case 6:
                skeletonRender = <VariantSkeleton key={key} />;
                break;
            case 7:
                skeletonRender = <CatSkeleton key={key} />;
                break;
            case 8:
                skeletonRender = <MobListSkeleton key={key} />;
                break;
            case 9:
                skeletonRender = <PDPSkeleton key={key} />;
                break;
            case 10:
                skeletonRender = <MobPDPSkeleton key={key} />;
                break;
            case 11:
                //Skeleton for home page banner
                skeletonRender = <HomePageBannerSkeleton key={key}/>;
                break;
            case 13:
                //skelton for variants on homepage
                skeletonRender = <HomePageVariantSkeleton key={key}/>;
                break;
            case 14:
                //skeleton for cart page
                skeletonRender = <CartSkeleton key={key}/>;
                break;
            case 15:
                //category on home page
                skeletonRender = <CategorySkeleton key={key}/>;
                break;
            default:
                skeletonRender = <Skeleton2 key={key} />;
                break;
        }

        return skeletonRender;
    }
    render() {
        return (
            <div className="skeleton-pattern">
                {
                    this.props.pattern.map((v, k) => {
                        return this.skeletonFactoryPattern(v, k);
                    })
                }
            </div>
        );
    }
}

export default SkeletonPattern;