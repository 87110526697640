import React, { Component } from 'react';
import styles from './skeletons.module.scss';

class PDPSkeleton extends Component {
    render() {
        return (
            <div className={styles['pdp-skeleton'] + " HK-pagecontainer"}>
                <div className={styles['pskel-breadcumbs-cont']}>
                    <div className={styles['pskel-breadcumbs']} />
                    <div className={styles['pskel-pincode']} />
                </div>
                <div className={styles['pskel-image-cont']}>
                    <div className={styles['pskel-image-left']}>
                        <div className={styles['pskel-image']} />
                        <div className={styles['pskel-small-images']}>
                            <div className={styles['pskel-small-img']} />
                            <div className={styles['pskel-small-img']} />
                            <div className={styles['pskel-small-img']} />
                            <div className={styles['pskel-small-img']} />
                            <div className={styles['pskel-small-img']} />
                            <div className={styles['pskel-small-img']} />
                        </div>
                    </div>
                    <div className={styles['pskel-image-right']}>
                        <div className={styles['pskel-catname']} />
                        <div className={styles['pskel-name']} />
                        <div className={styles['pskel-name-2']} />
                        <div className={styles['pskel-rating']} />
                        <div className={styles['pskel-mrp']} />
                        <div className={styles['pskel-price']} />
                        <div className={styles['pskel-taxes']} />
                        <div className={styles['pskel-content-certs']}>
                            <div className={styles['pskel-certs-img']} />
                            <div className={styles['pskel-certs-img']} />
                            <div className={styles['pskel-certs-img']} />
                            <div className={styles['pskel-certs-img']} />
                            <div className={styles['pskel-certs-img']} />
                            <div className={styles['pskel-certs-img']} />
                        </div>
                        <div className={styles['pskel-flv-wt']} />
                        <div className={styles['pskel-flv-wt-cont']}>
                            <div className={styles['pskel-flv-wt-item']} />
                            <div className={styles['pskel-flv-wt-item-1']} />
                            <div className={styles['pskel-flv-wt-item']} />
                            <div className={styles['pskel-flv-wt-item-1']} />
                        </div>
                        <div className={styles['pskel-flv-wt']} />
                        <div className={styles['pskel-flv-wt-cont']}>
                            <div className={styles['pskel-flv-wt-item']} />
                            <div className={styles['pskel-flv-wt-item-1']} />
                            <div className={styles['pskel-flv-wt-item']} />
                            <div className={styles['pskel-flv-wt-item-1']} />
                        </div>
                    </div>
                </div>
            </div>
            // <div className="hk-category-container">
            //     <div className="content-wrapper">
            //         <div className="category-main-container">
            //             <div className="span4 filter-box" style={{padding:"0","border":"0"}}>
            //                 <div className="skeleton"></div>
            //             </div>
            //             <div className="span12">
            //                 <div className="skeleton"></div>
            //             </div>
            //         </div>
            //         <div style={{marginTop:"40px"}}>
            //             <VariantSkeleton/>    
            //         </div>                    

            //     </div>
            // </div>
        );
    }
}

export default PDPSkeleton;