import React,{Component} from 'react';

class DesktopSliderSkeleton extends Component{
    render(){
        return(
            <div className="slider-skeleton">
                {/* <div className="slider-skeleton-left"> */}
                    <div className="skeleton-bar skeleton"></div>
                {/* </div>
                <div className="slider-skeleton-right">
                    <div className="skeleton-bar skeleton"></div>
                    <div className="skeleton-bar skeleton"></div>
                </div> */}
            </div>
        );
    }
}

export default DesktopSliderSkeleton;