import React,{Component} from 'react';
// import "static/css/global/skeleton.css";

class Skeleton2 extends Component{
    render(){
        return (
            <div className="skeleton2">
                <div className="skeleton-banner skeleton">
                    
                </div>
            </div>
        );
    }
}

export default Skeleton2;