import React,{Component} from 'react';
import SliderSkeleton from './desktopSliderSkeleton';
import VariantSkeleton from './variantSkeleton';
import styles from '../../../static//css/category/_desktop/index.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';

class CategoryPageSkeleton extends Component{
    render(){
        return(
            <div className="hk-category-container">
                <div className="content-wrapper">
                    <div className={convertToModule(styles,"category-main-container")}>
                        <div className={convertToModule(styles,"span4 filter-box")} style={{padding:"0","border":"0"}}>
                            <div className="skeleton"></div>
                        </div>
                        <div className={convertToModule(styles,"span12")}>
                            <SliderSkeleton/>
                            <VariantSkeleton/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoryPageSkeleton;