import React, { Component } from 'react';
import styles from './skeletons.module.scss';

class PDPSkeleton extends Component {
    render() {
        return (
            <div className={styles['mob-pdp-skeleton'] + " HK-pagecontainer"}>
                <div className={styles['mpskel-pincode']} />
                <div className={styles['mpskel-catname']} />
                <div className={styles['mpskel-name']} />
                <div className={styles['mpskel-name-2']} />
                <div className={styles['mpskel-name-3']} />
                <div className={styles['mpskel-rating']} />
                <div className={styles['mpskel-image']} />
                <div className={styles['mpskel-mrp']} />
                <div className={styles['mpskel-price']} />
                <div className={styles['mpskel-taxes']} />
                <div className={styles['mpskel-content-certs']}>
                    <div className={styles['mpskel-certs-img']} />
                    <div className={styles['mpskel-certs-img']} />
                    <div className={styles['mpskel-certs-img']} />
                    <div className={styles['mpskel-certs-img']} />
                    <div className={styles['mpskel-certs-img']} />
                    <div className={styles['mpskel-certs-img']} />
                </div>
            </div>
        );
    }
}

export default PDPSkeleton;