import React from 'react';
import Link from 'next/link';

class LinkHref extends React.Component {
    render() {
        const { to, className, onClick, nextJSReload, itemProp = '' } = this.props;
        return (
            nextJSReload ?
                <a href={to} className={className} onClick={onClick} itemProp={itemProp ? itemProp : ''}>
                    {
                        this.props.children
                    }
                </a>
                :
                <Link href={to} passHref>
                    <a className={className} onClick={onClick} itemProp={itemProp ? itemProp : ''}>
                        {
                            this.props.children
                        }
                    </a>
                </Link>
        )
    }
}

export default LinkHref;