import InView from 'react-intersection-observer';
import React from 'react';

const IntersectionObserver = (props = {}) => {

    const filteredProps = () => {
        let newProps = {...props}
        delete newProps.as;
        return newProps;
    }

    return (
        typeof window != 'undefined' ?
            <InView as="div" triggerOnce threshold={1} {...props}>
                {
                    props.children
                }
            </InView>
            :
            (
                props.as
                    ?
                    <props.as {...filteredProps()}>
                        {
                            props.children
                        }
                    </props.as>
                    :
                    <div {...props}>
                        {
                            props.children
                        }
                    </div>
            )
    )
}

export default IntersectionObserver;